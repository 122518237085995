import React from "react";
import { FormatPhoneNumber } from "@components/Locations/Locations";
import { Media, ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Icon } from "@ryerson/frontend.assets";
import { EmailLink, Link } from "@ryerson/frontend.navigation";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { useApplication } from "@ryerson/frontend.application";
import { YourStaticContent } from "./Content";
import { ContactUsExpert } from "./ContactUsExpert";
import { ContactUsPlant } from "./ContactUsPlant";
import { useStaticQuery, graphql } from "gatsby";
import { useEcommerce } from "@ryerson/frontend.e-commerce";
import { mix } from "polished";

//default plant number is lisle
const defaultPlantNumber = "1007";

export type YourMetalExpertsStaticContent = {
	titleNotLoggedIn: string;
	titleLoggedIn: string;
	navLabel: string;
	questions: string;
	phoneNumber: string;
	talkToAMetalExpert: string;
	seeAllOffices: string;
	localOffice: string;
	emailMeLink: string;
};

type MyRep = {
	name: string;
	title: string;
	imageUrl: string;
	email?: string;
	phone?: string;
};

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 20px;
`;

const TalkButton = styled.div`
	display: block;
	width: 50%;
	height: 110px;
	position: absolute;
	bottom: 0;
	box-sizing: border-box;
	padding: 35px 40px;
`;

const CircleContainer = styled.div`
	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	vertical-align: top;
	margin-right: 20px;
	line-height: 42px;
	text-align: center;
`;

const MobileLocationDetails = styled.div`
	display: block;
	width: 100%;
	height: 310px;
	border-radius: 2px;
	position: relative;
	padding: 25px 20px 25px 0px;
	box-sizing: border-box;
`;

const PersonBanner = styled.div`
	display: block;
	width: auto;
	height: auto;
	background-color: rgba(0, 0, 0, 0.7);
`;

type YourMetalExpertProps = {
	variant?: "global" | "contact_us" | "contact_us_plant";
};

const defaultOSRImageURL = "/static-assets/images/metal-experts/osrDefault.png";

const DEFAULT_REP: MyRep = {
	name: "",
	title: "",
	imageUrl: "",
	email: "",
	phone: "",
};

const YourMetalExperts: React.FC<YourMetalExpertProps> = ({ variant = "global" }) => {
	const defaultPortrait =
		process.env.GATSBY_COMPANY === "southernToolSteel"
			? "/static-assets/images/metal-experts/isrDefaultSTS.png"
			: "/static-assets/images/metal-experts/isrDefault.png";
	const { theme } = useTheme();
	const context = useApplication();
	const {
		localization: { language },
		profile,
	} = context;

	const { salesPlant } = useEcommerce();

	const queryData: any = useStaticQuery(graphql`
		query AllLocationsForExperts {
			allContentfulLocation {
				nodes {
					slug
					facilityType
					company
					phoneNumber
					streetAddress
					city
					state
					zipCode
					country
					locationName
					sapPlantNumber
					node_locale
					location {
						lat
						lon
					}
					locationHours {
						mondayOpen
						mondayClose
						tuesdayOpen
						tuesdayClose
						wednesdayOpen
						wednesdayClose
						thursdayOpen
						thursdayClose
						fridayOpen
						fridayClose
					}
					managementTeamMember {
						name
						emailAddress
						title
						cdtMember
						image {
							file {
								url
							}
						}
					}
				}
			}
		}
	`);

	const { white, link } = theme.colors.primary;

	const hoverColor = mix(0.65, link, white);

	const [userPlant, setUserPlant] = React.useState<any>(defaultPlantNumber);
	const [defaultState, setDefaultState] = React.useState<any>("Illinois");
	let availableStates: any = [];
	let stateOptions: any = [];
	let languageContent: any = [];
	if (queryData) {
		queryData.allContentfulLocation.nodes.forEach((node: any, index: number) => {
			if (
				node.node_locale.split("-").shift() === language &&
				node.company.includes(
					process.env.GATSBY_COMPANY === "ryerson" ? "Ryerson" : "Southern Tool Steel"
				)
			) {
				if (!availableStates.includes(node.state)) availableStates.push(node.state);
				languageContent.push(node);
			}
			if (index === queryData.allContentfulLocation.nodes.length - 1) {
				availableStates.sort();
				for (let i = 0; i < availableStates.length; i++) {
					stateOptions.push({
						display: availableStates[i],
						value: availableStates[i],
					});
				}
			}
		});
	}

	const [myISR, setMyISR] = React.useState<MyRep>({
		...DEFAULT_REP,
		imageUrl: defaultPortrait,
	});
	const [myOSR, setMyOSR] = React.useState<MyRep>({
		...DEFAULT_REP,
		imageUrl: defaultOSRImageURL,
	});

	const [myLocation, setMyLocation] = React.useState<any>({
		city: "",
		country: "",
		locationHours: {
			mondayOpen: "",
			mondayclose: "",
			tuesdayOpen: "",
			tuesdayClose: "",
			wednesdayOpen: "",
			wednesdayClose: "",
			thursdayOpen: "",
			thursdayClose: "",
			fridayOpen: "",
			fridayClose: "",
		},
		locationName: "",
		managementTeamMember: [],
		node_locale: "",
		phoneNumber: "",
		sapPlantNumber: "",
		slug: "",
		state: "",
		streetAddress: "",
		zipCode: "",
	});
	React.useEffect(() => {
		if (salesPlant && salesPlant.sapPlantNumber) {
			setUserPlant(salesPlant.sapPlantNumber);
		}
	}, [salesPlant]);

	React.useEffect(() => {
		if (profile?.company?.customerNumber) {
			setMyISR({
				...DEFAULT_REP,
				imageUrl: defaultPortrait,
			});
			setMyOSR({
				...DEFAULT_REP,
				imageUrl: defaultOSRImageURL,
			});
		}
	}, [profile?.company?.customerNumber]);

	React.useEffect(() => {
		if (userPlant && userPlant.length > 0) {
			if (
				queryData.allContentfulLocation.nodes &&
				queryData.allContentfulLocation.nodes.length > 0
			) {
				let hasISR = false;
				let hasOSR = false;
				if (context.user.isLoggedIn) {
					if (context.profile && context.profile.isr && context.profile.isr.active) {
						setMyISR({
							name: context.profile.isr.name,
							title: "Inside Sales Representative",
							imageUrl:
								"/static-assets/images/metal-experts/isr/" +
								context.profile.isr.id +
								".png",
							phone: context.profile.isr.phone,
							email: context.profile.isr.email,
						});
						hasISR = true;
					}
					if (context.profile && context.profile.owner && context.profile.owner.active) {
						//type in application context needs to be updated to support id for owner.
						let tmp: any = context.profile.owner;
						setMyOSR({
							name: tmp.name,
							title: "Outside Sales Representative",
							imageUrl: "/static-assets/images/metal-experts/osr/" + tmp.id + ".png",
							phone: tmp.phone,
							email: tmp.email,
						});
						hasOSR = true;
					}
				}
				let foundLocation = false;
				queryData.allContentfulLocation.nodes.forEach((node: any, index: number) => {
					if (node.sapPlantNumber) {
						if (
							node.sapPlantNumber === userPlant &&
							node.node_locale.split("-").shift() === language &&
							node.company.includes(
								process.env.GATSBY_COMPANY === "ryerson"
									? "Ryerson"
									: "Southern Tool Steel"
							)
						) {
							foundLocation = true;
							setMyLocation(node);
							setDefaultState(node.state);
							if (!hasISR && !hasOSR) {
								if (
									node.managementTeamMember &&
									node.managementTeamMember.length > 0
								) {
									let isr: MyRep | null = null;
									let osr: MyRep | null = null;
									node.managementTeamMember.forEach(
										(member: any, index: number) => {
											if (member.cdtMember === true) {
												if (isr === null) {
													isr = {
														name: member.name,
														title: member.title,
														imageUrl:
															member.image?.file?.url ??
															defaultPortrait,
														email: member.emailAddress,
													};
												} else {
													osr = {
														name: member.name,
														title: member.title,
														imageUrl:
															member.image?.file?.url ??
															defaultPortrait,
														email: member.emailAddress,
													};
												}
											}
											if (index === node.managementTeamMember.length - 1) {
												if (isr) setMyISR(isr);
												if (osr) setMyOSR(osr);
											}
										}
									);
								}
							}
						}
					}
					if (index === queryData.allContentfulLocation.nodes.length - 1) {
						if (!foundLocation) {
							let first = queryData.allContentfulLocation.nodes.find(
								(node: any) =>
									node.node_locale.split("-").shift() === language &&
									node.company.includes(
										process.env.GATSBY_COMPANY === "ryerson"
											? "Ryerson"
											: "Southern Tool Steel"
									)
							);

							setMyLocation(first);
							setDefaultState(first.state);
							if (!hasISR && !hasOSR) {
								if (
									first.managementTeamMember &&
									first.managementTeamMember.length > 0
								) {
									let isr: MyRep | null = null;
									let osr: MyRep | null = null;
									first.managementTeamMember.forEach(
										(member: any, index: number) => {
											if (member.cdtMember === true) {
												if (isr !== null) {
													isr = {
														name: member.name,
														title: member.title,
														imageUrl:
															member.image?.file?.url ??
															defaultPortrait,
														email: member.emailAddress,
													};
												} else {
													osr = {
														name: member.name,
														title: member.title,
														imageUrl:
															member.image?.file?.url ??
															defaultPortrait,
														email: member.emailAddress,
													};
												}
											}
											if (index === first.managementTeamMember.length - 1) {
												if (isr) setMyISR(isr);
												if (osr) setMyOSR(osr);
											}
										}
									);
								}
							}
						}
					}
				});
			}
		} else {
			//default to lisle
			setUserPlant("1007");
		}
	}, [userPlant, context.user.isLoggedIn, profile?.company?.customerNumber]);

	const getState = (location: any) => {
		if (location) {
			if (states[location.country]) {
				for (let i = 0; i < states[location.country].length; i++) {
					if (states[location.country][i].label === location.state) {
						return states[location.country][i].id;
					}
				}
				return location.state;
			} else {
				if (location.country === "Mexico") {
					return "Mexico";
				}
			}
		} else {
			return "";
		}
	};

	const getLink = (location: any) => {
		if (location) {
			if (states[location.country]) {
				for (let i = 0; i < states[location.country].length; i++) {
					if (states[location.country][i].label === location.state) {
						return (
							"/locations/" +
							location.country.toLowerCase().replace(" ", "-") +
							"/" +
							states[location.country][i].urlLabel
						);
					}
				}
			} else {
				return "/locations";
			}
		} else {
			return "/locations";
		}
	};
	if (variant === "global") {
		return (
			<>
				<Media greaterThanOrEqual="lg">
					<ContentSection type="secondary" vPadding="70px">
						<Spacer
							css={css`
								height: 75px;
							`}
						/>
						<Flex direction="row" alignItems="flex-end" justifyContent="space-between">
							<FlexItem
								css={css`
									max-width: 540px;
								`}
							>
								<Typography
									variant="h1"
									css={css`
										letter-spacing: -2px;
										line-height: 55px !important;
									`}
								>
									{context &&
									context.user &&
									context.user.isLoggedIn &&
									context.profile &&
									context.profile.company &&
									context.profile.company.companyName ? (
										<>
											<Typography
												variant="span"
												color={theme.colors.primary.gray}
											>
												{YourStaticContent[language].titleLoggedIn}
											</Typography>
											<Typography
												variant="span"
												color={theme.colors.primary.header}
											>
												{" " + context.profile.company.companyName}
											</Typography>
										</>
									) : (
										<>
											<Typography
												variant="span"
												color={theme.colors.primary.gray}
											>
												{YourStaticContent[language].titleNotLoggedIn}
											</Typography>
											<Typography
												variant="span"
												color={theme.colors.primary.header}
											>
												{" " + myLocation.city + " " + getState(myLocation)}
											</Typography>
										</>
									)}
								</Typography>
							</FlexItem>
							<FlexItem>
								<Typography
									variant="div"
									type="secondary"
									size="sm"
									css={css`
										text-align: right;
										line-height: 25px !important;
									`}
								>
									{YourStaticContent[language].questions}
								</Typography>
								<Typography
									variant="div"
									color={theme.colors.primary.header}
									weight="bold"
									css={css`
										text-align: right;
										font-size: 20px !important;
										line-height: 25px !important;
									`}
								>
									{YourStaticContent[language].phoneNumber}
								</Typography>
							</FlexItem>
						</Flex>
						<Flex
							direction="row"
							justifyContent={
								myOSR?.name?.length && myISR?.name?.length
									? "space-between"
									: "flex-start"
							}
							css={css`
								margin-top: 75px;
							`}
						>
							<FlexItem
								css={css`
									width: 560px;
									height: 360px;
									background-color: ${theme.colors.primary.background};
									border-radius: 2px;
									position: relative;
									padding: 33px 40px 0px 40px;
									box-sizing: border-box;
									margin-right: ${myOSR?.name?.length === 0 ||
									myISR?.name?.length === 0
										? "40px"
										: "0"};
								`}
							>
								<Typography
									variant="div"
									size="sm"
									color={theme.colors.primary.gray}
								>
									{YourStaticContent[language].localOffice}
								</Typography>
								<Typography
									variant="div"
									size="lg"
									weight="bold"
									css={css`
										font-size: 20px !important;
										margin-bottom: 30px;
										line-height: 30px !important;
									`}
									color={theme.colors.primary.header}
								>
									{myLocation.locationName}
								</Typography>
								<Typography variant="div" size="md" type="primary">
									{myLocation.streetAddress}
								</Typography>
								<Typography variant="div" size="md" type="primary">
									{myLocation.city +
										" " +
										getState(myLocation) +
										" " +
										myLocation.zipCode}
								</Typography>
								<Typography variant="div" size="md" type="primary">
									{myLocation.phoneNumber
										? FormatPhoneNumber(myLocation.phoneNumber)
										: ""}
								</Typography>
								<TalkButton
									onClick={() => {
										if (window && (window as any).LiveChatWidget) {
											(window as any).LiveChatWidget.call("maximize");
										}
									}}
									data-test-id="your-metal-expert-talk-to-a-metal-expert-button"
									css={css`
										cursor: pointer;
										left: 0px;
										background-color: ${theme.colors.primary.accentBrand};
										border-top: 1px solid ${theme.colors.primary.accentBrand};
									`}
								>
									<CircleContainer
										css={css`
											background-color: ${theme.colors.primary
												.secondaryBrand};
										`}
									>
										<Icon
											size="xs"
											icon="chevron-right"
											color={theme.colors.primary.white}
										/>
									</CircleContainer>
									<Typography
										size="sm"
										weight="bold"
										variant="div"
										color={theme.colors.primary.secondaryBrand}
										css={css`
											white-space: pre-line;
											display: inline-block;
											vertical-align: top;
											max-width: calc(100% - 60px);
										`}
									>
										{YourStaticContent[language].talkToAMetalExpert}
									</Typography>
								</TalkButton>
								<Link to={getLink(myLocation) ?? "#"}>
									<TalkButton
										css={css`
											right: 0px;
											border-top: 1px solid
												${theme.colors.primary.lighterGray};
										`}
										data-test-id="your-metal-expert-see-all-offices-button"
									>
										<CircleContainer
											css={css`
												background-color: ${theme.colors.primary
													.primaryBrand};
											`}
										>
											<Icon
												size="xs"
												icon="chevron-right"
												color={theme.colors.primary.white}
											/>
										</CircleContainer>
										<Typography
											size="sm"
											weight="bold"
											variant="div"
											color={theme.colors.primary.secondaryBrand}
											css={css`
												white-space: pre-line;
												display: inline-block;
												vertical-align: top;
												max-width: calc(100% - 60px);
											`}
										>
											{YourStaticContent[language].seeAllOffices.replace(
												"\n",
												myLocation.state + "\n"
											)}
										</Typography>
									</TalkButton>
								</Link>
							</FlexItem>

							{myOSR?.name && myOSR?.name?.length > 0 && (
								<FlexItem
									css={css`
										width: 260px;
										height: 360px;
										position: relative;
										border-radius: 2px;
										overflow: hidden;
										img {
											object-fit: cover;
											height: 100%;
											width: 100%;
											object-position: center center;
										}
									`}
								>
									<img
										src={myOSR.imageUrl}
										onError={() => {
											if (myOSR.imageUrl !== defaultPortrait) {
												setMyOSR((state) => ({
													...state,
													imageUrl: defaultPortrait,
												}));
											}
										}}
									/>
									<PersonBanner
										css={css`
											position: absolute;
											left: 0px;
											bottom: 40px;
											z-index: 1;
											padding-left: 40px;
											padding-right: 20px;
											padding: 8px 20px 8px 40px;
										`}
									>
										<Typography
											size="sm"
											color={theme.colors.primary.white}
											variant="div"
										>
											{myOSR.title}
										</Typography>
										<Typography
											variant="div"
											weight="bold"
											size="sm"
											color={theme.colors.primary.white}
										>
											{myOSR.name}
										</Typography>

										{myOSR?.email && myOSR?.email?.length > 0 ? (
											<Typography variant="div" size="sm">
												<EmailLink to={myOSR.email}>
													<Icon
														hoverColor={hoverColor}
														icon="mail"
														css={css`
															cursor: pointer;
															margin-right: 8px;
														`}
														size="xs"
														color={theme.colors.primary.link}
													/>
													{YourStaticContent[language].emailMeLink}
												</EmailLink>
											</Typography>
										) : null}
									</PersonBanner>
								</FlexItem>
							)}
							{myISR?.name && myISR?.name?.length > 0 && (
								<FlexItem
									css={css`
										width: 260px;
										height: 360px;
										position: relative;
										border-radius: 2px;
										overflow: hidden;
										img {
											object-fit: cover;
											object-position: center center;
											height: 100%;
											width: 100%;
										}
									`}
								>
									<img
										src={myISR.imageUrl}
										onError={() => {
											if (myISR.imageUrl !== defaultPortrait) {
												setMyISR((state) => ({
													...state,
													imageUrl: defaultPortrait,
												}));
											}
										}}
									/>
									<PersonBanner
										css={css`
											position: absolute;
											left: 0px;
											bottom: 40px;
											padding: 8px 20px 8px 40px;
											z-index: 1;
										`}
									>
										<Typography
											size="sm"
											color={theme.colors.primary.white}
											variant="div"
										>
											{myISR.title}
										</Typography>
										<Typography
											variant="div"
											weight="bold"
											size="sm"
											color={theme.colors.primary.white}
										>
											{myISR.name}
										</Typography>
										{myISR?.email && myISR?.email?.length > 0 ? (
											<Typography variant="div" size="sm">
												<EmailLink to={myISR.email}>
													<Icon
														hoverColor={hoverColor}
														icon="mail"
														css={css`
															cursor: pointer;
															margin-right: 8px;
														`}
														size="xs"
														color={theme.colors.primary.link}
													/>
													{YourStaticContent[language].emailMeLink}
												</EmailLink>
											</Typography>
										) : null}
									</PersonBanner>
								</FlexItem>
							)}
						</Flex>
					</ContentSection>
				</Media>
				<Media lessThan="lg">
					<ContentSection type="primary" vPadding="40px" hPadding="0px">
						<Flex
							direction="row"
							justifyContent="space-between"
							css={css`
								margin-bottom: 28px;
								height: auto;
							`}
						>
							{myOSR?.name && myOSR?.name?.length > 0 && (
								<FlexItem
									css={css`
										width: calc(50% - 10px);
										border-radius: 2px;
										overflow: hidden;
										img {
											object-fit: cover;
											height: calc(50vw - 30px);
											width: 100%;
											object-position: center center;
										}
									`}
								>
									<img
										src={myOSR.imageUrl}
										onError={() => {
											if (myOSR.imageUrl !== defaultPortrait) {
												setMyOSR((state) => ({
													...state,
													imageUrl: defaultPortrait,
												}));
											}
										}}
									/>
									<Typography
										variant="div"
										weight="bold"
										size="md"
										type="primary"
										css={css`
											margin-top: 17px;
											line-height: 16px !important;
										`}
									>
										{myOSR.name}
									</Typography>
									<Typography
										size="md"
										type="primary"
										variant="div"
										css={css`
											line-height: 16px !important;
										`}
									>
										{myOSR.title}
									</Typography>
									{myOSR?.email && myOSR?.email?.length > 0 ? (
										<Typography
											variant="div"
											size="md"
											css={css`
												padding-top: 5px;
											`}
										>
											<EmailLink to={myOSR.email}>
												<Icon
													hoverColor={hoverColor}
													icon="mail"
													css={css`
														cursor: pointer;
														margin-right: 8px;
													`}
													size="xs"
													color={theme.colors.primary.link}
												/>
												{YourStaticContent[language].emailMeLink}
											</EmailLink>
										</Typography>
									) : null}
								</FlexItem>
							)}
							{myISR?.name && myISR?.name?.length > 0 && (
								<FlexItem
									css={css`
										width: calc(50% - 10px);
										border-radius: 2px;
										height: auto;
										overflow: hidden;
										img {
											object-fit: cover;
											object-position: center center;
											width: 100%;
											height: calc(50vw - 30px);
											display: block;
										}
									`}
								>
									<img
										src={myISR.imageUrl}
										onError={() => {
											if (myISR.imageUrl !== defaultPortrait) {
												setMyISR((state) => ({
													...state,
													imageUrl: defaultPortrait,
												}));
											}
										}}
									/>

									<Typography
										variant="div"
										weight="bold"
										size="md"
										type="primary"
										css={css`
											margin-top: 17px;
											line-height: 16px !important;
										`}
									>
										{myISR.name}
									</Typography>
									<Typography
										size="md"
										type="primary"
										variant="div"
										css={css`
											line-height: 16px !important;
										`}
									>
										{myISR.title}
									</Typography>
									{myISR?.email && myISR?.email?.length > 0 ? (
										<Typography
											variant="div"
											size="md"
											css={css`
												padding-top: 5px;
											`}
										>
											<EmailLink to={myISR.email}>
												<Icon
													hoverColor={hoverColor}
													icon="mail"
													css={css`
														cursor: pointer;
														margin-right: 8px;
													`}
													size="xs"
													color={theme.colors.primary.link}
												/>
												{YourStaticContent[language].emailMeLink}
											</EmailLink>
										</Typography>
									) : null}
								</FlexItem>
							)}
						</Flex>
						<MobileLocationDetails
							css={css`
								background-color: ${theme.colors.primary.background};
								border: 1px solid ${theme.colors.primary.lighterGray};
								padding-left: 20px;
							`}
						>
							<Typography variant="div" size="sm" color={theme.colors.primary.gray}>
								{YourStaticContent[language].localOffice}
							</Typography>
							<Typography
								variant="div"
								size="lg"
								weight="bold"
								css={css`
									margin-bottom: 20px;
									line-height: 20px !important;
								`}
								color={theme.colors.primary.header}
							>
								{myLocation.locationName}
							</Typography>
							<Typography variant="div" size="md" type="primary">
								{myLocation.streetAddress}
							</Typography>
							<Typography variant="div" size="md" type="primary">
								{myLocation.city +
									" " +
									getState(myLocation) +
									" " +
									myLocation.zipCode}
							</Typography>
							<Typography variant="div" size="md" type="primary">
								{myLocation.phoneNumber
									? FormatPhoneNumber(myLocation.phoneNumber)
									: ""}
							</Typography>
							<TalkButton
								onClick={() => {
									if (window && (window as any).LiveChatWidget) {
										(window as any).LiveChatWidget.call("maximize");
									}
								}}
								css={css`
									left: 0px;
									cursor: pointer;
									height: 140px;
									background-color: ${theme.colors.primary.accentBrand};
									border-top: 1px solid ${theme.colors.primary.accentBrand};
									border-bottom: 1px solid ${theme.colors.primary.accentBrand};
									padding: 17px 20px;
								`}
							>
								<CircleContainer
									css={css`
										background-color: ${theme.colors.primary.secondaryBrand};
										position: absolute;
										bottom: 20px;
										right: 20px;
										height: 24px;
										width: 24px;
										border-radius: 24px;
										margin-right: 0px;
										line-height: 25px;
										display: block;
									`}
								>
									<Icon
										size="xs"
										icon="chevron-right"
										color={theme.colors.primary.white}
									/>
								</CircleContainer>
								<Typography
									size="md"
									variant="div"
									color={theme.colors.primary.secondaryBrand}
								>
									{YourStaticContent[language].talkToAMetalExpert}
								</Typography>
							</TalkButton>
							<Link to={getLink(myLocation) ?? "#"}>
								<TalkButton
									css={css`
										right: 0px;
										border-top: 1px solid ${theme.colors.primary.lighterGray};
										height: 140px;
										border-bottom: 1px solid ${theme.colors.primary.lighterGray};
										border-right: 1px solid ${theme.colors.primary.lighterGray};
										padding: 17px 20px;
									`}
								>
									<CircleContainer
										css={css`
											background-color: ${theme.colors.primary.primaryBrand};
											position: absolute;
											bottom: 20px;
											right: 20px;
											margin-right: 0px;
											height: 24px;
											width: 24px;
											border-radius: 24px;
											line-height: 25px;
											display: block;
										`}
									>
										<Icon
											size="xs"
											icon="chevron-right"
											color={theme.colors.primary.white}
										/>
									</CircleContainer>
									<Typography
										size="md"
										variant="div"
										color={theme.colors.primary.secondaryBrand}
									>
										{YourStaticContent[language].seeAllOffices.replace(
											"\n",
											myLocation.state + "\n"
										)}
									</Typography>
								</TalkButton>
							</Link>
						</MobileLocationDetails>
						<Typography
							variant="div"
							type="primary"
							size="md"
							css={css`
								margin-top: 35px;
							`}
						>
							{YourStaticContent[language].questions}
						</Typography>
						<Typography
							variant="h1"
							weight="bolder"
							type="primary"
							css={css`
								letter-spacing: -2px;
							`}
						>
							{YourStaticContent[language].phoneNumber}
						</Typography>
						<Spacer
							css={css`
								height: 15px;
							`}
						/>
					</ContentSection>
				</Media>
			</>
		);
	} else if (variant === "contact_us") {
		return (
			<ContactUsExpert
				content={{
					contactName: myISR.name,
					contactEmail: myISR.email,
					contactPhone: myISR.phone,
					contactTitle: myISR.title,
					contactImageUrl: myISR.imageUrl,
				}}
			/>
		);
	} else if (variant === "contact_us_plant") {
		return (
			<ContactUsPlant
				defaultState={defaultState}
				stateOptions={stateOptions}
				languageContent={languageContent}
			/>
		);
	} else {
		return <></>;
	}
};

export default YourMetalExperts;

export const states: any = {
	"United States": [
		{
			id: "AL",
			urlLabel: "alabama",
			label: "Alabama",
		},
		{
			id: "AK",
			urlLabel: "alaska",
			label: "Alaska",
		},
		{
			id: "AZ",
			urlLabel: "arizona",
			label: "Arizona",
		},
		{
			id: "AR",
			urlLabel: "arkansas",
			label: "Arkansas",
		},
		{
			id: "CA",
			urlLabel: "california",
			label: "California",
		},
		{
			id: "CO",
			urlLabel: "colorado",
			label: "Colorado",
		},
		{
			id: "CT",
			urlLabel: "connecticut",
			label: "Connecticut",
		},
		{
			id: "DE",
			urlLabel: "delaware",
			label: "Delaware",
		},
		{
			id: "FL",
			urlLabel: "florida",
			label: "Florida",
		},
		{
			id: "GA",
			urlLabel: "georgia",
			label: "Georgia",
		},
		{
			id: "HI",
			urlLabel: "hawaii",
			label: "Hawaii",
		},
		{
			id: "ID",
			urlLabel: "idaho",
			label: "Idaho",
		},
		{
			id: "IL",
			urlLabel: "illinois",
			label: "Illinois",
		},
		{
			id: "IN",
			urlLabel: "indiana",
			label: "Indiana",
		},
		{
			id: "IA",
			urlLabel: "iowa",
			label: "Iowa",
		},
		{
			id: "KS",
			urlLabel: "kansas",
			label: "Kansas",
		},
		{
			id: "KY",
			urlLabel: "kentucky",
			label: "Kentucky",
		},
		{
			id: "LA",
			urlLabel: "louisiana",
			label: "Louisiana",
		},
		{
			id: "ME",
			urlLabel: "maine",
			label: "Maine",
		},
		{
			id: "MD",
			urlLabel: "maryland",
			label: "Maryland",
		},
		{
			id: "MA",
			urlLabel: "massachusetts",
			label: "Massachusetts",
		},
		{
			id: "MI",
			urlLabel: "michigan",
			label: "Michigan",
		},
		{
			id: "MN",
			urlLabel: "minnesota",
			label: "Minnesota",
		},
		{
			id: "MS",
			urlLabel: "mississippi",
			label: "Mississippi",
		},
		{
			id: "MO",
			urlLabel: "missouri",
			label: "Missouri",
		},
		{
			id: "MT",
			urlLabel: "montana",
			label: "Montana",
		},
		{
			id: "NE",
			urlLabel: "nebraska",
			label: "Nebraska",
		},
		{
			id: "NV",
			urlLabel: "nevada",
			label: "Nevada",
		},
		{
			id: "NH",
			urlLabel: "new-hampshire",
			label: "New Hampshire",
		},
		{
			id: "NJ",
			urlLabel: "new-jersey",
			label: "New Jersey",
		},
		{
			id: "NM",
			urlLabel: "new-mexico",
			label: "New Mexico",
		},
		{
			id: "NY",
			urlLabel: "new-york",
			label: "New York",
		},
		{
			id: "NC",
			urlLabel: "north-carolina",
			label: "North Carolina",
		},
		{
			id: "ND",
			urlLabel: "north-dakota",
			label: "North Dakota",
		},
		{
			id: "OH",
			urlLabel: "ohio",
			label: "Ohio",
		},
		{
			id: "OK",
			urlLabel: "oklahoma",
			label: "Oklahoma",
		},
		{
			id: "OR",
			urlLabel: "oregon",
			label: "Oregon",
		},
		{
			id: "PA",
			urlLabel: "pennsylvania",
			label: "Pennsylvania",
		},
		{
			id: "RI",
			urlLabel: "rhode-island",
			label: "Rhode Island",
		},
		{
			id: "SC",
			urlLabel: "south-carolina",
			label: "South Carolina",
		},
		{
			id: "SD",
			urlLabel: "south-dakota",
			label: "South Dakota",
		},
		{
			id: "TN",
			urlLabel: "tennessee",
			label: "Tennessee",
		},
		{
			id: "TX",
			urlLabel: "texas",
			label: "Texas",
		},
		{
			id: "UT",
			urlLabel: "utah",
			label: "Utah",
		},
		{
			id: "VT",
			urlLabel: "vermont",
			label: "Vermont",
		},
		{
			id: "VA",
			urlLabel: "virginia",
			label: "Virginia",
		},
		{
			id: "WA",
			urlLabel: "washington",
			label: "Washington",
		},
		{
			id: "WV",
			urlLabel: "west-virginia",
			label: "West Virginia",
		},
		{
			id: "WI",
			urlLabel: "wisconsin",
			label: "Wisconsin",
		},
		{
			id: "WY",
			urlLabel: "wyoming",
			label: "Wyoming",
		},
	],
	Canada: [
		{
			id: "AB",
			urlLabel: "alberta",
			label: "Alberta",
		},
		{
			id: "BC",
			urlLabel: "british-columbia",
			label: "British Columbia",
		},
		{
			id: "MB",
			urlLabel: "manitoba",
			label: "Manitoba",
		},
		{
			id: "NB",
			urlLabel: "new-brunswick",
			label: "New Brunswick",
		},
		{
			id: "ON",
			urlLabel: "ontario",
			label: "Ontario",
		},
		{
			id: "QC",
			urlLabel: "quebec",
			label: "Quebec",
		},
	],
};
